import React, { useRef, useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import restaurantLocations from './RestaurantLocations.json';

const transformLocations = (locationsData) => {
  return locationsData.map(location => ({
    lat: location.latitude,
    lng: location.longitude,
    name: location.Restaurant,
    group: location.Group,
    address: location.Address
  }));
};


const ViewMap2 = () => {
  const center = { 'lat': 43.71102425, 'lng': -79.75449125 };
  const locations = transformLocations(restaurantLocations);

  const [radius, setRadius] = useState(1100); // Initial radius state
  const radiusRef = useRef(radius); // Mutable reference for the radius

  // Update radiusRef whenever the radius state changes
  useEffect(() => {
    radiusRef.current = radius;
  }, [radius]);

  const handleRadiusChange = (event) => {
    setRadius(parseInt(event.target.value, 10));
  };

  const renderMarkers = (map, maps) => {
    // Home marker:
    new maps.Marker({
      position: { 'lat': 43.699070, 'lng': -79.748060 },
      map,
      label: `Home`,
      icon: {
        path: maps.SymbolPath.CIRCLE,
        scale: 8, // Size of the icon
        fillColor: "#4285F4", // Blue color
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: "white",
      },
      zIndex: maps.Marker.MAX_ZINDEX + 1, // Ensures it's on top
    });

    locations.forEach((location, index) => {
      // Create a marker
      const marker = new maps.Marker({
        position: location,
        map,
        icon: {
          path: maps.SymbolPath.CIRCLE,
          scale: 8, // Size of the icon
          fillColor: "#DD2200", // Red color
          fillOpacity: 1,
          strokeWeight: 2,
          strokeColor: "white",
        }
        // label: `${index + 1}`,
      });
  
      // Define the content of the InfoWindow
      const infoWindowContent = `<div>
        <h3>${location.name}</h3>
        <p>Group: ${location.group}</p>
      </div>`;
  
      // Create an InfoWindow
      const infoWindow = new maps.InfoWindow({
        content: infoWindowContent,
      });
  
      // Add a click listener to the marker to open the InfoWindow
      marker.addListener('click', () => {
        infoWindow.open(map, marker);
      });
    });
  };

  const handleApiLoaded = ({ map, maps }) => {
    renderMarkers(map, maps);

    map.addListener("click", (e) => {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();

      // Use radiusRef.current to access the current radius
      // [2, 3, 4].forEach(multiplier => {
      //   new maps.Circle({
      //     strokeColor: ["#FF0000", "#00FF00", "#0000FF"][multiplier - 2],
      //     strokeOpacity: 0.8,
      //     strokeWeight: 2,
      //     fillColor: ["#FF0000", "#00FF00", "#0000FF"][multiplier - 2],
      //     fillOpacity: 0,
      //     map,
      //     center: { lat, lng },
      //     radius: radiusRef.current * multiplier, // Now using the current radius
      //   });
      // });

      const size = 2700;
      
      // Define the offsets for each vertex (scaled for a polygon size of approximately 1000m)
      const offsets = [
        // { latOffset: 0.004563894138334674, lngOffset: -0.006114815011740092 },
        // { latOffset: -0.004563894138334674, lngOffset: -0.006114815011740092 },
        // { latOffset: -0.004563894138334674, lngOffset: 0.006114815011740092 },
        // { latOffset: 0.004563894138334674, lngOffset: 0.006114815011740092 }

        // { latOffset: 0.004938840747187925, lngOffset: -0.005816179537088225 },
        // { latOffset: -0.004170935924362316, lngOffset: -0.006389318105133869 },
        // { latOffset: -0.004938840747187925, lngOffset: 0.005816179537088225 },
        // { latOffset: 0.004170935924362316, lngOffset: 0.006389318105133869 }
        
        // { latOffset: 0.004369571150939124, lngOffset: -0.006255153098894393 },
        // { latOffset: -0.004753713113221304, lngOffset: -0.005968442340419769 },
        // { latOffset: -0.004369571150939124, lngOffset: 0.006255153098894393 },
        // { latOffset: 0.004753713113221304, lngOffset: 0.005968442340419769 }
        
        { latOffset: 0.006386041189931125, lngOffset: -0.008758123569794914 },
        { latOffset: 0.007074672489082962, lngOffset: 0.007699854439592285 },
        { latOffset: -0.006441923551171608, lngOffset: 0.008675339087546405 },  // could be negatives of p1
        { latOffset: -0.007018571428571668, lngOffset: -0.007777500000000057 }  // could be negatives of p2
      ];
    
      // Calculate the vertices of the polygon based on the offsets
      const vertices = offsets.map(offset => ({
        // lat: lat + offset.latOffset*1.41421356237*size/1000,
        // lng: lng + offset.lngOffset*1.41421356237*size/1000
        lat: lat + offset.latOffset*size/1000,
        lng: lng + offset.lngOffset*size/1000
      }));
    
      // Create the polygon
      new maps.Polygon({
        paths: vertices,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0,
        map: map
      });
      
      const size2 = 4700
      const vertices2 = offsets.map(offset => ({
        lat: lat + offset.latOffset*size2/1000,
        lng: lng + offset.lngOffset*size2/1000
      }));
    
      // Create the polygon
      new maps.Polygon({
        paths: vertices2,
        strokeColor: '#00FF00',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#00FF00',
        fillOpacity: 0.05,
        map: map
      });
      
      const size3 = 6000
      const vertices3 = offsets.map(offset => ({
        lat: lat + offset.latOffset*size3/1000,
        lng: lng + offset.lngOffset*size3/1000
      }));
    
      // Create the polygon
      new maps.Polygon({
        paths: vertices3,
        strokeColor: '#0000FF',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#0000FF',
        fillOpacity: 0,
        map: map
      });
    });
  };

  return (
    // <div style={{ height: '90vh', width: '100%' }}>
    <div style={{ height: '100vh', width: '100%' }}>
      {/* <input
        type="number"
        value={radius}
        onChange={handleRadiusChange}
        placeholder="Enter radius (in meters)"
      /> */}
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
        defaultCenter={center}
        defaultZoom={12}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={handleApiLoaded}
      >
      </GoogleMapReact>
    </div>
  );
};

export default ViewMap2;
