import React, { useState } from "react";
import Modal from "../../components/common/Modal";
import "./RegisterModal.css";
import { signupUser, loginUser } from "../../api/restaurants_api";
import { useAuth } from "../../contexts/AuthContext";

const RegisterModal = ({ onClose, initSignUp }) => {
  const [isSignUp, setIsSignUp] = useState(initSignUp);
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const { authState, setAuthState } = useAuth()

  const handleMobileNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    if (value.length <= 10) {
      setMobileNumber(value); // Update state only if 10 or fewer digits
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  // Handler for the signup form submission
  const handleSignup = async (event) => {
    event.preventDefault();
    const userData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: mobileNumber,
      password: password,
      // Any other fields that your backend expects
      // Should add role at some point
    };

    try {
      const response = await signupUser(userData);
      console.log('Signup successful, received response:', response);
      localStorage.setItem('accessToken', response.access);
      localStorage.setItem('refreshToken', response.refresh);
      onClose()
      // Likely redirect to /nearby
    } catch (error) {
      // Handle errors, such as displaying a message to the user
      console.log(error)
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const userData = {
      email: email,
      username: email,
      password: password,
      // Todo: probably replace password altogether with phone check
    };

    try {
      const response = await loginUser(userData);
      console.log('Login successful, received response:', response);
      localStorage.setItem('accessToken', response.access);
      localStorage.setItem('refreshToken', response.refresh);
      onClose()
      // Likely redirect to /nearby
    } catch (error) {
      // Handle errors, such as displaying a message to the user
      console.log(error)
    }
  };

  return (
    <Modal onClose={onClose} dontCloseOutside>
      <div className="register-modal-content">
        <h2>{isSignUp ? "Sign Up" : "Log In"}</h2>
        {isSignUp ? (
          <form className="register-form" onSubmit={handleSignup}>
            <div className="form-row">
              <input type="text" placeholder="First Name" onChange={handleFirstNameChange} required />
              <input type="text" placeholder="Last Name" onChange={handleLastNameChange} required />
            </div>
            <input type="email" placeholder="Email" onChange={handleEmailChange} required />
            <div className="form-row">
              <select name="country-code" required>
                <option value="+1">+1 (CA)</option>
              </select>
              <input
                type="tel"
                placeholder="Mobile Number"
                value={mobileNumber}
                onChange={handleMobileNumberChange}
                minLength={10}
                maxLength={10}
                required
              />
            </div>
            <div className="form-row password-row">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password (10+ characters)"
                value={password}
                onChange={handlePasswordChange}
                minLength={10}
                required
              />
              <button
                type="button"
                onClick={toggleShowPassword}
                className="toggle-password"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            <button type="submit">Sign Up</button>
            <div className="terms">
              By tapping "Sign Up", you agree to the{" "}
              <a href="/terms">Terms and Conditions</a> and{" "}
              <a href="/privacy">Privacy Policy</a>.
            </div>
          </form>
        ) : (
          <form className="register-form" onSubmit={handleLogin}>
            <input type="email" placeholder="Email" onChange={handleEmailChange} required />
            <div className="form-row password-row">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
                required
              />
              <button
                type="button"
                onClick={toggleShowPassword}
                className="toggle-password"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            <button type="submit">Log In</button>
          </form>
        )}
        <div className="toggle">
          <span>
            {isSignUp ? "Already have an account?" : "Don't have an account?"}
          </span>
          <button
            onClick={() => {
              setIsSignUp(!isSignUp);
              setPassword("");
              setEmail("")
            }}
          >
            {isSignUp ? "Log In" : "Sign Up"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RegisterModal;
