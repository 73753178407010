import React from 'react';
import GoogleMapReact from 'google-map-react';
import restaurantLocations from './RestaurantLocations.json';

const transformLocations = (locationsData) => {
  return locationsData.map(location => ({
    lat: location.latitude,
    lng: location.longitude,
    name: location.Restaurant,
    group: location.Group,
    address: location.Address
  }));
};

const ViewMap = () => {
  const center = {'lat': 43.71102425, 'lng': -79.75449125}
  const restaurants = transformLocations(restaurantLocations)
  const locations = [{'lat': 43.696724999999994, 'lng': -79.846143}, {'lat': 43.684188750000004, 'lng': -79.82326700000002}, {'lat': 43.6716525, 'lng': -79.800391}, {'lat': 43.65911625, 'lng': -79.777515}, 
  {'lat': 43.64658, 'lng': -79.754639}, {'lat': 43.71282480000001, 'lng': -79.82651580000001}, {'lat': 43.700112999999995, 'lng': -79.80427344999998}, {'lat': 43.687401200000004, 'lng': -79.7820311}, {'lat': 43.6746894, 'lng': -79.75978874999998}, {'lat': 43.6619776, 'lng': -79.7375464}, {'lat': 43.7289246, 'lng': -79.8068886}, {'lat': 43.71603725, 'lng': -79.78527989999999}, {'lat': 43.70314990000001, 'lng': -79.7636712}, {'lat': 43.69026254999999, 'lng': -79.74206249999999}, {'lat': 43.67737520000001, 'lng': -79.72045380000002}, {'lat': 43.7450244, 'lng': -79.7872614}, {'lat': 43.73196149999999, 'lng': -79.76628634999999}, {'lat': 43.718898599999996, 'lng': -79.7453113}, {'lat': 43.705835699999994, 'lng': -79.72433625}, {'lat': 43.6927728, 'lng': -79.70336119999999}, {'lat': 43.7611242, 'lng': -79.7676342}, {'lat': 43.74788574999999, 'lng': -79.74729279999998}, {'lat': 43.7346473, 'lng': -79.7269514}, {'lat': 43.721408849999996, 'lng': -79.70661}, {'lat': 43.7081704, 'lng': -79.68626859999999}, {'lat': 43.777224, 'lng': -79.748007}, {'lat': 43.76381, 'lng': -79.72829924999999}, {'lat': 43.750396, 'lng': -79.7085915}, {'lat': 43.736982000000005, 'lng': -79.68888375}, {'lat': 43.723568, 'lng': -79.669176}]

 const renderMarkers = (map, maps) => {
  // let marker = new maps.Marker({
  // position: {'lat': 43.71102425, 'lng': -79.75449125},
  // map,
  // // title: 'Hello World!'
  // label: '1'
  // });
  // return marker;
  locations.forEach((location, index) => {
    const groupRestaurants = restaurants.filter(restaurant => restaurant.group === index + 1);

    const marker = new maps.Marker({
      position: location,
      map,
      label: `G${index + 1} (${groupRestaurants.length})`
    });

    // Define the content of the InfoWindow
    const infoWindowContent = `<div>
        <h3>Number of Restaurants: ${groupRestaurants.length}</h3>
        <ul>
            ${groupRestaurants.map(restaurant => `<li>${restaurant.name}</li>`).join('')}
        </ul>
    </div>`;

    // Create an InfoWindow
    const infoWindow = new maps.InfoWindow({
      content: infoWindowContent,
    });

    // Add a click listener to the marker to open the InfoWindow
    marker.addListener('click', () => {
      infoWindow.open(map, marker);
    });
  });
 };

 return (
   <div style={{ height: '100vh', width: '100%' }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
      defaultCenter={center}
      defaultZoom={12}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
    >
    </GoogleMapReact>
   </div>
 );
};

export default ViewMap;