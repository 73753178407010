import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "../screens/HomePage/HomePage";
import ViewMap from "../screens/ViewMap/ViewMap";
import ViewMap2 from "../screens/ViewMap/ViewMap2";
// import MerchantsPage from "../screens/MerchantsPage/MerchantsPage";
// import RestaurantPage from "../screens/RestaurantPage/RestaurantPage";
// import NearbyRestaurants from "../screens/NearbyRestaurants/NearbyRestaurants";
// import ModifyPage from "../screens/ModifyPage/ModifyPage";
// import UserOrdersPage from "../screens/UserOrdersPage/UserOrdersPage";
// import InvitePage from "../screens/InvitePage/InvitePage";
// import UserAccountPage from "../screens/UserAccountPage/UserAccountPage";

const AppRoutes = () => {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/viewmap2" element={<ViewMap2 />} />
				<Route path="/viewmap" element={<ViewMap />} />
				{/* <Route path="/merchants" element={<MerchantsPage />} />
				<Route path="/nearby" element={<NearbyRestaurants />} />
				<Route path="/:id" element={<RestaurantPage />} />
				<Route path="/modify/:id" element={<ModifyPage />} /> 
				<Route path="/orders" element={<UserOrdersPage />} />
				<Route path="/invite" element={<InvitePage />} />
				<Route path="/account" element={<UserAccountPage />} /> */}
				{/* Other routes go here */}
			</Routes>
		</Router>
	);
};

export default AppRoutes;
