import React from "react";
import "./Modal.css";

const Modal = ({ onClose, dontCloseOutside=false, fromItemModal=false, buttonText, handleAddToOrder, children }) => {
	const handleBackgroundClick = (e) => {
		e.stopPropagation();
		if (!dontCloseOutside) {
			onClose();
		}
	};
	const handleModalClick = (e) => {
		e.stopPropagation();
	};

	return (
		<div className="modal-background" onClick={handleBackgroundClick}>
			<div className="modal-container" style={fromItemModal ? {height: '100%'} : {}} onClick={handleModalClick}>
				<div onClick={onClose} className="close-button">
					✖
				</div>
        <div className="modal-content">
          {children}
        </div>
				{fromItemModal && <button className="add-to-order" onClick={handleAddToOrder}>
          <span className="item-price">{buttonText}</span>
        </button>}
			</div>
		</div>
	);
};

export default Modal;

