import React, { useState, useEffect, useRef } from "react";
import "./HomePage.css";
import Button from "../../components/common/Button";
import { useLoadScript } from "@react-google-maps/api";
import RegisterModal from "./RegisterModal";

const libraries = ["places"];

const HomePage = () => {
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
		libraries,
	});

	const [address, setAddress] = useState("");
	const [registerType, setRegisterType] = useState("");
	const autocompleteRef = useRef(null);

	useEffect(() => {
		if (isLoaded) {
			let autocomplete = new window.google.maps.places.Autocomplete(
				autocompleteRef.current,
				{ types: ["address"] }
			);

			// Create a new session token for the Autocomplete
			const sessionToken =
				new window.google.maps.places.AutocompleteSessionToken();
			autocomplete.setOptions({ sessionToken: sessionToken });

			autocomplete.addListener("place_changed", () => {
				const selectedPlace = autocomplete.getPlace();
				const selectedAddress = selectedPlace.formatted_address || "";

				if (selectedPlace.geometry) {
					const latitude = selectedPlace.geometry.location.lat();
					const longitude = selectedPlace.geometry.location.lng();
					console.log("Selected address:", selectedAddress);
					console.log("Latitude:", latitude, "Longitude:", longitude);
				}

				setAddress(selectedAddress);
			});
		}
	}, [isLoaded]);

	const onSubmit = () => {
		console.log("Address submitted:", address);
	};

	const handleCloseModal = () => {
		setRegisterType("")
	}

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			onSubmit();
		}
	};

	const ORANGE = "#FF8C42"
	const RED = "#D83367"
	const GREEN = "#4CAF50"

	return (
		<div>
			<div className="homepage-container">
				<div style={{ width: "100%" }}>
					<div className="top-bar">
						<div className="logo-container">
							{/* <span style={{ color: "#FF6B6B" }}>Dash</span>
							<span style={{ color: "#FFD460" }}>Dish</span>
							<span style={{ color: "#2E7D32" }}>Dine</span> */}
							{/* <span style={{ color: GREEN }}>Dash</span> */}
							<span style={{ color: GREEN }}>Speedy</span>
							<span style={{ color: RED }}>Feast</span>
						</div>
						<div className="login-signup-buttons">
							<Button variant="btn-signin" label="Log In" onClick={() => setRegisterType("login")} />
							<Button label="Sign Up" onClick={() => setRegisterType("signup")} />
						</div>
					</div>
				</div>
				<div className="address-input">
					<input
						// ref={autocompleteRef}
						type="text"
						placeholder="Enter your address..."
						// value={address}
						// onChange={(e) => setAddress(e.target.value)}
						// onKeyDown={handleKeyDown}
					/>
					<Button label="Search Nearby Restaurants" onClick={onSubmit} />
				</div>
			</div>
			<div className="registration-actions">
				<div className="register-section">
					<Button label="Deliver With Us" style={{ backgroundColor: GREEN }}/>
					<div className="undraw-img">
						<img src="/undraw_dash.svg" alt="delivery agent" />
					</div>
					<div className="register-details">
						<span>🗸</span> Skip the tipping casino and get paid directly and competitively.<br/>
						<span>🗸</span> Choose your own hours and get started!
					</div>
				</div>
				<div className="register-section">
					<Button label="Partner With Us" style={{ backgroundColor: ORANGE }}/>
					<div className="undraw-img">
					<img src="/undraw_dish.svg" alt="chefs" />
					</div>
					<div className="register-details">
						<span>🗸</span> Find and serve clients with lowest cost-to-client service fees in industry.<br/>
						<span>🗸</span> Our transparency and lack of hidden fees ensure you keep the largest percentage of order value relative to competitors.<br/>
						<span>🗸</span> Accentuate your business reputation by serving clients reliably and transparently.
					</div>
				</div>
				<div className="register-section">
					<Button label="Sign Up and Start Ordering" style={{ backgroundColor: RED }} onClick={() => setRegisterType("signup")}/>
					<div className="undraw-img">
					<img src="/undraw_dine.svg" alt="ordering on food delivery app" />
					</div>
					<div className="register-details">
						<span>🗸</span> No Tips. No Hidden Fees. Pay What You See.<br/>
						<span>🗸</span> Experience a simple and efficient user interface with minimal clutter.<br/>
						<span>🗸</span> Order from the best restaurants in town and always find the best deals and promotions.<br/>
						<span>🗸</span> Receive special discounts for ordering often - save time everyday and pay the lowest margins by ordering with us regularly.
					</div>
				</div>
			</div>
			{registerType && <RegisterModal onClose={handleCloseModal} initSignUp={registerType === "signup"}/>}
		</div>
	);
};

export default HomePage;
