import axios from "axios";

// Set up a default config for axios
const apiClient = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
	timeout: 10000, // Set a timeout limit
	headers: {
		"Content-Type": "application/json",
		// Any other headers you might want to add
	},
});

// Function to create a new restaurant
export const createRestaurant = async (
	name,
	address,
	phone,
	latitude,
	longitude
) => {
	try {
		const requestBody = {
			name: name,
			address: address,
			phone: phone,
			latitude: latitude,
			longitude: longitude,
		};

		const response = await apiClient.post("/restaurants/create/", requestBody);
		return response.data;
	} catch (error) {
		console.error("Error creating a new restaurant:", error);
	}
};

// Function to get nearby restaurants based on latitude and longitude
export const getNearbyRestaurants = async (lat, lng) => {
	try {
		const response = await apiClient.get(
			`/nearby?latitude=${lat}&longitude=${lng}`
		);
		console.log(response.data);
		return response.data;
	} catch (error) {
		console.error("Error fetching nearby restaurants:", error);
	}
};

export const getRestaurantById = async (restaurantId) => {
	try {
		const response = await apiClient.get(`/restaurants/${restaurantId}/`);
		return response.data;
	} catch (error) {
		console.error(
			`Error fetching details for restaurant ID ${restaurantId}/:`,
			error
		);
	}
};

// Function to update a restaurant's menu
export const updateMenu = async (
	restaurantId, oldMenuId, newMenu
) => {
	try {
		const requestBody = {
			restaurantId, oldMenuId, newMenu
		};
		console.log('attempting to update menu:')
		console.log(requestBody)
		// return

		const response = await apiClient.post("/menus/update/", requestBody);
		return response.data;
	} catch (error) {
		console.error("Error updating menu:", error);
	}
};


// Either rename this file or put these elsewhere:

export const signupUser = async (userData) => {
    try {
        const response = await apiClient.post('/signup/', userData);
        return response.data; // Contains the refresh and access tokens
    } catch (error) {
        console.error('Error during the signup process:', error);
    }
};

export const loginUser = async (credentials) => {
    try {
        const response = await apiClient.post('/login/', credentials);
        return response.data; // Contains the refresh and access tokens
    } catch (error) {
        console.error('Error during the login process:', error);
    }
};

export const refreshToken = async (refreshToken) => {
    try {
        const response = await apiClient.post('/api/token/refresh/', {
            refresh: refreshToken,
        });
        return response.data; // Contains the new access token
    } catch (error) {
        console.error('Error refreshing the token:', error);
    }
};

export const addAddress = async (addressText, lat, lng) => {
    try {
        const requestBody = {
            address_text: addressText,
            lat: lat,
            lng: lng,
        };

        const token = localStorage.getItem('accessToken');
        const response = await apiClient.post("/add_address/", requestBody, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error adding or updating the address:", error);
    }
};

