import React from "react";
import PropTypes from "prop-types";
import "./Button.css";

const Button = ({
	label,
	onClick,
	variant = "default",
	type = "button",
	...otherProps
}) => {
	return (
		<button
			className={`btn ${variant}`}
			onClick={onClick}
			type={type}
			{...otherProps}
		>
			{label}
		</button>
	);
};

Button.propTypes = {
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	variant: PropTypes.string, // for different button styles
	type: PropTypes.oneOf(["button", "submit", "reset"]), // HTML button types
};

export default Button;
